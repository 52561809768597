<template>
  <div class="ghs_login">
    <div class="login">
      <div class="login_title">登陆</div>
      <div class="from_table">
        
          <van-field
            label="账号"
            label-width="60px"
            v-model="mobile"
            placeholder="请输入手机号或账号"
            clearable
          />
          <van-field
            label-width="60px"
            v-model="password"
            :placeholder="codeType == 1 ? '请输入密码' : '请输入验证码'"
            :type="codeType == 1 ? 'password' : 'text'"
            clearable
          >
            <div slot="label">{{codeType == 1 ? '密码' : '验证码'}}</div>
            <div slot="button">
              <!-- <van-button v-if="codeType == 1" size="small" class="forget_pwd" @click="forgetpwd">忘记密码</van-button> -->
              <van-button
                v-if="codeType == 2"
                @click="sendCode"
                type="warning"
                size="mini"
                :disabled="btnDisabled"
                 native-type="button"
              >
                <van-count-down
                  v-if="showTxt" 
                  ref="daojishi"
                  class="control-count-down"
                  :time="time"
                  @finish="finishTime"
                >
                  <template v-slot="timeData">
                    <span class="seconds">{{ timeData.seconds }}后重新发送</span>
                  </template>
                </van-count-down>
                <span v-if="!showTxt" class="seconds">发送验证码</span>
              </van-button>

            </div>
          </van-field>
          <div class="from_btn">
            <van-button native-type="button" @click="formSubmit" block round>立即登录</van-button>
          </div>
          <div class="bottom">
            <van-cell :border="false">
              <div slot="title">
                <van-button native-type="button" @click="codeTypeChange" size="small">{{codeType == 1 ? '验证码登陆' : '密码登陆'}}</van-button>
              </div>
              <div>
                <!-- <van-button @click="goReigst" size="small">忘记密码</van-button> -->
              </div>
            </van-cell>
          </div>
        
      </div>
    </div>
      
  </div>
</template>
<script>
export default {
  name: "GhsLogin",
  data() {
    return {
      mobile: '',
      code: '',
      password: '',
      codeType: 1,
      time: -1,
      showTxt: false,
      btnDisabled: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("init");
    },
    /**
     * 验证手机号
     */
    preMobel (val) {
      return /^1[23456789]\d{9}$/.test(val);
    },
    codeTypeChange() {
      if (this.codeType == 1) {
        this.codeType = 2;
      }else {
        this.codeType = 1;
      }
    },
    sendCode() {
      let that = null;
      that = this;
      if (!that.btnDisabled) {
        if (!that.preMobel(that.mobile)) {
          that.$toast("手机号不正确");
          return false;
        }
        that.dosendcode();
      }  
    },
    dosendcode() {
      console.log("dddddd");
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
      .post(
        that.$store.state.domain + "web/user/send_sms",
        that.$qs.stringify({
          tel: that.mobile,
          type: 'login', // 注册用的验证码模板
        })
      )
      // that.$toast(res.data.msg ? res.data.msg : "发送成功");
      
      this.time = 60 * 1000;
      this.btnDisabled = true;
      this.showTxt = true;
    },
    finishTime() {
      this.btnDisabled = false;
      this.showTxt = false;
      this.$refs.daojishi.reset();
    },
    formSubmit() {
      console.log("formSubmit");
      let that = null;
      that = this;
      if (!that.preMobel(that.mobile)) {
        that.$toast("请输入正确的手机号");
        return false;
      }
      if (!that.password || that.password.length <= 0) {
        if (that.codeType == 1) {
          that.$toast("请输入密码");
        }else {
          that.$toast("请输入验证码");
        }
        return false;
      }

      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
      .post(
        that.$store.state.domain + "shopsmobile/user/login",
        that.$qs.stringify({
          username: that.mobile,
          password: that.password,
          codeType: that.codeType,
          gonghuo: 1
        })
      )
      .then(res => {
        if (res.data && res.data.code == 100000) {
          that.$toast("登陆成功");
          localStorage.setItem("DK_GHLOGIN", true);
          localStorage.setItem("DK_GHUID", res.data.data);
          localStorage.setItem("DK_SHOUHOU", res.data.is_shouhou);
          setTimeout(() => {
            that.$router.back();
          }, 1000)
        }
      })

    },
  },
}
</script>
<style lang="less">
.ghs_login {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  text-align: left;
  .login {
    padding: 20px;
  }
  .login_title {
    margin-top: 40px;
    font-weight: 700;
    font-size: 26px;
    display: inline-block;
    position: relative;
    color: #414244;
  }
  .van-cell__value {
    flex: 1;
  }
  .from_table {
    width: 100%;
    margin-top: 20px;
  }
  .from_table .van-cell {
    padding: 10px 0;
  }
  .forget_pwd {
    border: 0;
    color: #999;
  }
  .forget_pwd::after,
  .forget_pwd::before {
    border: 0;
  }
  .from_btn {
    margin: 20px 0;
  }

  .from_btn .van-button {
    background-color: #FDD736;
  }

  .bottom .van-cell {
    padding: 10px 0;
  }
  .bottom .van-cell .van-button,
  .bottom .van-cell .van-button::after,
  .bottom .van-cell .van-button::before {
    border: 0;
  }

  .seconds {
    color: #fff;
    font-size: 12px;
  }
}
</style>